.App {
  text-align: center;
  color: black;

}
.App-header {
  text-align: center;
}
.title {
  text-align: center;
}
h2{
  text-align: center;
}
